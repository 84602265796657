@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  @apply text-[55px] xxs:text-[64px] xs:text-[82px] sm:text-[96px] md:text-[96px] lg:text-[130px] xl:text-[140px] font-bold leading-none;
}

h2 {
  @apply text-5xl font-bold leading-none;
}

h3 {
  @apply text-3xl md:text-[35px] lg:text-[41px] font-bold leading-tight;
}

h4 {
  @apply text-3xl font-bold leading-tight;
}

h5 {
  @apply text-lg font-bold leading-tight;
}

p {
  @apply font-normal;
}

.container-p {
  @apply px-4;
}

.container-thin {
  @apply px-4;
}

.container-f {
  @apply px-4 sm:px-16;
}

.header-fullscreen {
  height: calc(100vh - 4.5rem);
}

video {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.video-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.service-image-tag {
  position: absolute;
  top: 0.625rem;
  left: 0.625rem;
  width: 42px;
  height: 42px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2px);
  border-radius: 22px;
  padding-top: 5px;
  padding-left: 1px;
}

.nav-menu a {
  @apply mx-2 text-base lg:text-lg;
}

.nav-menu a:hover {
  @apply text-smooth-red;
}

.menu-burger-nav a {
  @apply text-lg mt-4;
}

.service-poster {
  transition: all 0.3s ease;
  transform: translateY(0px);
}

.service-poster:hover {
  transform: translateY(-1rem);
  -webkit-box-shadow: 0px 21px 43px -8px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 21px 43px -8px rgba(120, 120, 120, 1);
  box-shadow: 0px 21px 43px -8px rgba(120, 120, 120, 1);
}

.service-poster:hover ~ .hover-red-h3 {
  @apply text-smooth-red;
  transition: all 0.3s ease;
}

.userform {
  @apply p-8;
}

input[type='text'],
input[type='email'] {
  @apply h-12 text-lg px-2 mt-2 border-none;
  background-color: #f5f5f5;
}

.checkbox-label {
  display: grid;
  grid-template-columns: 1rem auto;
  gap: 0.8rem;
}

input[type='checkbox'] {
  @apply box-border border-none bg-[#f0f0f0];
  border: 0.575px solid #f0f0f0;
  border-radius: 2.875px;
  accent-color: #4cb651;
}

input[type='checkbox']::after {
  color: white;
}

.submit-btn {
  @apply w-[187px] h-12 bg-[#232323] rounded-full text-base text-white;
}

.error-span {
  @apply text-smooth-red;
}

.success-span {
  @apply text-[#4CB651];
}

.footer-links a {
  @apply mt-2;
}

.hover-red a:hover {
  @apply text-smooth-red;
}

.hover-red-h3:hover {
  @apply text-smooth-red;
}

.modal p {
  @apply mt-4;
}

.modal {
  @apply max-h-[90vh] overflow-y-scroll;
  @apply flex flex-col bg-white p-8 sm:p-16 xs:mx-4 max-w-5xl;
}

.popup-modal-overlay {
  /*background: rgba(209, 24, 13, 0.8);*/
  background: rgba(0, 0, 0, 0.8);
}
